import { useState, useEffect } from "react";
import FacebookLogin from "react-facebook-login";
import { useAuth } from "../hooks/useAuth";
import classNames from "classnames";
import qs from "querystring";

import { useRouter } from "next/router";

import Button from "./Button";
import { useWindowSize } from "../hooks";

const STATE_INITIAL_EMAIL = 0;
const STATE_CREATE_ACCOUNT = 1;
const STATE_PASSWORD = 2;
const STATE_PASSWORD_RESET_SENT = 3;

export default function LoginModalInner({
  onSuccess,
  targetResource,
  hideAccountCreation,
  ssoOnly,
  aarpOnly,
  sundanceOnly,
  ticketBucket
}) {
  const {
    lookup,
    login,
    facebookLogin,
    idaLogin,
    eventivalLogin,
    aarpLogin,
    sundanceLogin,
    register,
    forgotPassword,
    tenant,
    singleOrganization
  } = useAuth();

  const { query, pathname, replace } = useRouter();
  const [state, setState] = useState(STATE_INITIAL_EMAIL);
  const [loading, setLoading] = useState(false);
  const [idaLoading, setIdaLoading] = useState(false);
  const [eventivalLoading, setEventivalLoading] = useState(false);
  const [aarpLoading, setAarpLoading] = useState(false);
  const [sundanceLoading, setSundanceLoading] = useState(false);

  const [lookupError, setLookupError] = useState(null);
  const [email, setEmail] = useState("");
  const [greetingName, setGreetingName] = useState("");

  const [createAccountError, setCreateAccountError] = useState(null);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [loginError, setLoginError] = useState(null);
  const [loginPassword, setLoginPassword] = useState("");
  const [loadingForgot, setLoadingForgot] = useState(false);

  const canLookup = !!email;
  const canCreateAccount = name && password && password === confirmPassword;

  const { width } = useWindowSize();
  const shouldAutofocus = width > 640;

  const [isApp, setIsApp] = useState(false);
  const [isAppleApp, setIsAppleApp] = useState(false);
  const [isAndroidApp, setIsAndroidApp] = useState(false);
  const [isAppLockdownMode, setIsAppLockdownMode] = useState(false);

  useEffect(() => {
    const isApple =
      navigator.userAgent.toLowerCase().indexOf("gonative") > -1 &&
      /iPad|iPhone|iPod/.test(navigator.userAgent);

    const isAndroid =
      navigator.userAgent.toLowerCase().indexOf("gonativeandroid") > -1;

    setIsApp(isApple || isAndroid);
    setIsAppleApp(isApple);
    setIsAndroidApp(isAndroid);
    setIsAppLockdownMode(
      tenant?.apple_app_lockdown_mode ? true : false
    );
  }, []);

  useEffect(() => {
    const {
      ida_auth,
      eventival_auth,
      aarp_auth,
      sundance_auth,
      sundance_token,
      error,
      error_description,
      token
    } = query;

    if (ida_auth) {
      replace(pathname, window.location.pathname, { shallow: true });
      if (error) {
        setLookupError(`${error}: ${error_description}`);
      } else {
        setIdaLoading(true);
        idaLogin({ token: token })
          .then(r => onSuccess && onSuccess(r))
          .catch(e => {
            setIdaLoading(false);
            if (e.message.indexOf("No active IDA membership found") !== -1) {
              setLookupError({ type: "ida-notfound" });
            } else {
              setLookupError(e.message);
            }
          });
      }
    } else if (eventival_auth) {
      replace(pathname, window.location.pathname, { shallow: true });
      if (error) {
        setLookupError(`${error}: ${error_description}`);
      } else {
        setEventivalLoading(true);
        eventivalLogin({ token: token })
          .then(r => onSuccess && onSuccess(r))
          .catch(e => {
            setEventivalLoading(false);
            setLookupError(e.message);
          });
      }
    } else if (aarp_auth) {
      replace(pathname, window.location.pathname, { shallow: true });
      if (error) {
        setLookupError(`${error}: ${error_description}`);
      } else {
        setAarpLoading(true);
        aarpLogin({
          token: token,
          targetResource: targetResource
        })
          .then(r => onSuccess && onSuccess(r))
          .catch(e => {
            setAarpLoading(false);
            if (e.message.indexOf("No active AARP account found") !== -1) {
              setLookupError({ type: "aarp-notfound" });
            } else {
              setLookupError(e.message);
            }
          });
      }
    } else if (sundance_auth) {
      replace(pathname, window.location.pathname, { shallow: true });
      if (error) {
        setLookupError(`${error}: ${error_description}`);
      } else {
        setSundanceLoading(true);
        sundanceLogin({
          token: sundance_token || token,
          eventBucket: tenant?.event_bucket || singleOrganization?.event_bucket,
          targetResource: targetResource
        })
          .then(r => onSuccess && onSuccess(r))
          .catch(e => {
            setSundanceLoading(false);
            setLookupError(e.message);
          });
      }
    }
  }, [
    query.ida_auth,
    query.eventival_auth,
    query.aarp_auth,
    query.sundance_auth
  ]);

  const onLookup = (e, forceEmail) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (!canLookup) {
      return;
    }

    setLookupError(null);
    setLoading(true);
    lookup(forceEmail || email)
      .then(({ data }) => {
        setLoading(false);
        if (!data.exists) {
          setState(STATE_CREATE_ACCOUNT);
        } else if (data.facebook_only) {
          setLookupError(
            `This account was created via Facebook. Please login using the "Continue with Facebook" button below.`
          );
        } else {
          if (data.first_name) {
            setGreetingName(data.first_name);
          }
          setState(STATE_PASSWORD);
        }
      })
      .catch(e => {
        let error;
        if (e.message.indexOf("(email-check)") !== -1) {
          const match = e.message.match(/\[(.*?)\]/);
          error = {
            type: "email-check",
            suggestion: match[1]
          };
        } else {
          error = e.message;
        }
        setLookupError(error);
        setLoading(false);
      });
  };

  const onFacebook = data => {
    if (data.error) {
      setLookupError(data.error.message || data.error);
    } else if (data.status === "not_authorized") {
      setLookupError("Unable to login to Facebook: User canceled login.");
    } else if (data.accessToken) {
      setLoading(true);
      facebookLogin({ token: data.accessToken, targetResource })
        .then(r => onSuccess && onSuccess(r))
        .catch(e => {
          setLoading(false);
          setLookupError(e.message);
        });
    } else {
      setLookupError("Unable to login to Facebook: An error occurred.");
    }
  };

  const onIda = () => {
    setIdaLoading(true);
    window.location.href = `${process.env.ENDPOINT}auth/ida?${qs.stringify({
      api_key: tenant.api_key,
      redirect:
        window.location.href +
        (window.location.href.indexOf("?") === -1 ? "?" : "&") +
        "ida_auth=true"
    })}`;
  };

  const onEventival = () => {
    setEventivalLoading(true);
    window.location.href = `${
      process.env.ENDPOINT
    }auth/eventival?${qs.stringify({
      api_key: tenant.api_key,
      redirect:
        window.location.href +
        (window.location.href.indexOf("?") === -1 ? "?" : "&") +
        "eventival_auth=true"
    })}`;
  };

  const onAarp = () => {
    setAarpLoading(true);
    window.location.href = `${process.env.ENDPOINT}auth/aarp?${qs.stringify({
      api_key: tenant?.api_key || singleOrganization?.api_key,
      ticket_bucket: ticketBucket?.id,
      promo_code: tenant?.aarp_registration_promo_code || "MFG",
      redirect:
        window.location.href +
        (window.location.href.indexOf("?") === -1 ? "?" : "&") +
        "aarp_auth=true"
    })}`;
  };

  const onSundance = () => {
    setSundanceLoading(true);
    window.location.href = `${process.env.ENDPOINT}auth/sundance?${qs.stringify(
      {
        api_key: tenant?.api_key || singleOrganization?.api_key,
        event_bucket: tenant?.event_bucket || singleOrganization?.event_bucket,
        redirect:
          window.location.href +
          (window.location.href.indexOf("?") === -1 ? "?" : "&") +
          "sundance_auth=true"
      }
    )}`;
  };

  const onCreateAccount = e => {
    e.preventDefault();
    e.stopPropagation();
    if (!canCreateAccount) {
      return;
    }

    setCreateAccountError(null);
    setLoading(true);
    register({ email, password, name })
      .then(r => onSuccess && onSuccess(r))
      .catch(e => {
        setLoading(false);
        setCreateAccountError(e.message);
      });
  };

  const onLogin = e => {
    e.preventDefault();
    e.stopPropagation();
    setLoginError(null);
    setLoading(true);
    login({ email, password: loginPassword, targetResource })
      .then(r => onSuccess && onSuccess(r))
      .catch(e => {
        setLoading(false);
        setLoginError(e.message);
      });
  };

  const onForgot = e => {
    e.preventDefault();
    e.stopPropagation();
    setLoginError(null);
    setLoadingForgot(true);
    forgotPassword({ email })
      .then(r => setState(STATE_PASSWORD_RESET_SENT))
      .catch(e => {
        setLoadingForgot(false);
        setLoginError(e.message);
      });
  };

  if (idaLoading) {
    return (
      <div className="text-center p-4 text-indigo-600 font-medium">
        Logging in via MyIDA...
      </div>
    );
  }

  if (eventivalLoading) {
    return (
      <div className="text-center p-4 text-indigo-600 font-medium">
        Logging in via Eventival...
      </div>
    );
  }

  if (aarpLoading) {
    return (
      <div className="text-center p-4 text-indigo-600 font-medium">
        Logging in via AARP...
      </div>
    );
  }

  if (sundanceLoading) {
    return (
      <div className="text-center p-4 text-indigo-600 font-medium">
        Logging in via Sundance...
      </div>
    );
  }

  if (ssoOnly) {
    return (
      <div className="text-center">
        <Button disabled={loading} onClick={onIda}>
          Login with MyIDA account
        </Button>
      </div>
    );
  }

  const use_aarp_registration_flow =
    tenant?.use_aarp_registration_flow ||
    singleOrganization?.use_aarp_registration_flow;

  if (aarpOnly) {
    return (
      <div className="text-center pb-4">
        <Button disabled={loading} onClick={onAarp}>
          Continue with AARP
        </Button>
      </div>
    );
  }

  if (sundanceOnly || singleOrganization?.sundance_login) {
    return (
      <div className="text-center p-4">
        <p className="text-center pb-4">
          Login with your Sundance account to access the content.
        </p>
        <Button disabled={loading} onClick={onSundance}>
          Login with Sundance account
        </Button>
      </div>
    );
  }

  return state === STATE_INITIAL_EMAIL ? (
    <div className="text-center">
      {use_aarp_registration_flow ? null : (
        <div>
          <p className="leading-snug text-always-gray-600 text-sm">
            To continue, please enter your email address.
          </p>
          <form id="lookup-form" className="flex mt-2" onSubmit={onLookup}>
            <div className="flex-1 relative rounded-md shadow-sm mr-2">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg
                  className="h-5 w-5 text-always-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884zM18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <input
                id="lookupEmail"
                name="lookupEmail"
                className="form-input block w-full pl-10 text-sm sm:leading-5"
                placeholder="you@example.com"
                type="email"
                autoFocus={shouldAutofocus}
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <Button type="submit" disabled={!canLookup || loading}>
              Continue
            </Button>
          </form>
        </div>
      )}
      {lookupError ? (
        <p className="mt-2 text-sm text-red-600">
          {lookupError.type === "ida-notfound" ? (
            <>
              It looks like your IDA membership has expired. To renew your IDA
              membership, please{" "}
              <a
                className="underline hover:text-red-400"
                href="https://documentary.org/membership"
              >
                click here
              </a>
              . If you believe this is an error, please contact{" "}
              <a
                className="underline hover:text-red-400"
                href="mailto:membership@documentary.org"
              >
                membership@documentary.org
              </a>
              .
            </>
          ) : lookupError.type === "email-check" ? (
            <>
              That email address doesn't seem quite right. Did you mean{" "}
              <button
                className="font-medium underline hover:text-red-400 focus:outline-none"
                onClick={() => {
                  setEmail(lookupError.suggestion);
                  onLookup(null, lookupError.suggestion);
                }}
              >
                {lookupError.suggestion}
              </button>
              ? If the original email address was correct, please click
              "Continue" again to proceed.
            </>
          ) : (
            lookupError
          )}
        </p>
      ) : null}
      {!tenant?.virtual_festival_disable_facebook_login ? (
        <>
          {use_aarp_registration_flow ? (
            <p className="text-center p-4">
              Login with your AARP account to access this screening.
            </p>
          ) : !isApp ? (
            <div className="my-2 text-center italic text-always-gray-600 font-bold text-sm">
              OR
            </div>
          ) : null}
          {tenant?.ida_login ? (
            <div>
              <Button disabled={loading} onClick={onIda}>
                Login with MyIDA account
              </Button>
              {!tenant?.ida_login_hide_custom_text ? (
                <div className="mt-4 text-always-gray-500 text-xs text-left">
                  Please note films on demand are only available to current IDA
                  members.{" "}
                  <a
                    href="https://www.documentary.org/membership"
                    target="_blank"
                    className="underline hover:opacity-75"
                  >
                    Click&nbsp;here
                  </a>{" "}
                  to join the IDA.
                </div>
              ) : null}
            </div>
          ) : singleOrganization?.eventival_login && tenant ? (
            <div>
              <Button disabled={loading} onClick={onEventival}>
                Login with Eventival
              </Button>
            </div>
          ) : use_aarp_registration_flow ? (
            <div className="pb-4">
              <Button disabled={loading} onClick={onAarp}>
                Continue with AARP
              </Button>
            </div>
          ) : !isApp ? (
            <div>
              <FacebookLogin
                appId="576095339262034"
                fields="name,email"
                size="small"
                textButton="Continue with Facebook"
                disableMobileRedirect={true}
                callback={onFacebook}
              />
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  ) : state === STATE_CREATE_ACCOUNT ? (
    isAppLockdownMode || hideAccountCreation ? (
      <div>
        <p className="text-center leading-snug text-always-gray-600 text-sm">
          You must have an existing account in order to access content in this
          app.
        </p>
        <div className="text-center" style={{ paddingTop: 10 }}>
          <Button onClick={onSuccess}>Close</Button>
        </div>
      </div>
    ) : (
      <form id="create-form" className="text-left" onSubmit={onCreateAccount}>
        <p className="text-center leading-snug text-always-gray-600 text-sm">
          Please provide the following information to create your new account.
        </p>
        <div className="mt-3">
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-5 text-always-gray-700"
          >
            Email
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <div className="relative flex-grow focus-within:z-10">
              <input
                id="createEmail"
                name="createEmail"
                className="form-input block w-full rounded-r-none text-sm sm:leading-5 cursor-not-allowed text-always-gray-400"
                value={email}
                type="email"
                disabled
              />
            </div>
            <span
              onClick={() => setState(STATE_INITIAL_EMAIL)}
              className="-ml-px relative inline-flex items-center px-4 py-2 border border-always-gray-300 text-sm leading-5 font-medium rounded-r-md text-always-gray-700 bg-always-gray-50 hover:text-always-gray-500 hover:bg-always-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-always-gray-100 active:text-always-gray-700 transition ease-in-out duration-150 cursor-pointer"
            >
              <span>Edit</span>
            </span>
          </div>
        </div>
        <div className="mt-3">
          <label
            htmlFor="name"
            className="block text-sm font-medium leading-5 text-always-gray-700"
          >
            Full name
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              id="name"
              name="name"
              className="form-input block w-full text-sm sm:leading-5"
              placeholder=""
              value={name}
              onChange={e => setName(e.target.value)}
              autoFocus={shouldAutofocus}
            />
          </div>
        </div>
        <div className="mt-3">
          <label
            htmlFor="password"
            className="block text-sm font-medium leading-5 text-always-gray-700"
          >
            Password
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              id="password"
              name="password"
              className="form-input block w-full text-sm sm:leading-5"
              placeholder=""
              type="password"
              autoComplete="new-password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-3">
          <label
            htmlFor="confirm_password"
            className="block text-sm font-medium leading-5 text-always-gray-700"
          >
            Confirm password
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              id="confirm_password"
              name="confirm_password"
              className="form-input block w-full text-sm sm:leading-5"
              placeholder=""
              type="password"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />
            {password !== confirmPassword ? (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <svg
                  className="h-5 w-5 text-red-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            ) : null}
          </div>
        </div>
        <div className="text-center my-6">
          <Button disabled={!canCreateAccount || loading} type="submit">
            Create account
          </Button>
        </div>
        {createAccountError ? (
          <p className="mt-2 text-sm text-red-600">{createAccountError}</p>
        ) : null}
        <p className="mt-6 leading-snug text-always-gray-400 text-sm">
          <div>
            By creating an account, you agree to the Eventive{" "}
            <a
              key="terms"
              target="_blank"
              href="https://eventive.org/terms"
              className="underline hover:text-always-gray-500"
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              key="privacy"
              target="_blank"
              href="https://eventive.org/privacy"
              className="underline hover:text-always-gray-500"
            >
              Privacy Policy
            </a>
            .
          </div>
        </p>
      </form>
    )
  ) : state === STATE_PASSWORD ? (
    <form id="password-form" className="text-center" onSubmit={onLogin}>
      <p className="leading-snug text-always-gray-600 text-sm">
        Welcome back{greetingName ? `, ${greetingName}` : ""}!
      </p>
      <div className="flex mt-4">
        <div className="flex-1 relative rounded-md shadow-sm mr-2">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg
              className="h-5 w-5 text-always-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 9V7C5 4.23858 7.23858 2 10 2C12.7614 2 15 4.23858 15 7V9C16.1046 9 17 9.89543 17 11V16C17 17.1046 16.1046 18 15 18H5C3.89543 18 3 17.1046 3 16V11C3 9.89543 3.89543 9 5 9ZM13 7V9H7V7C7 5.34315 8.34315 4 10 4C11.6569 4 13 5.34315 13 7Z"
              />
            </svg>
          </div>
          <input
            id="userPassword"
            name="userPassword"
            className="form-input block w-full pl-10 text-sm sm:leading-5"
            placeholder="Password"
            type="password"
            value={loginPassword}
            autoComplete="password"
            onChange={e => setLoginPassword(e.target.value)}
            autoFocus={shouldAutofocus}
          />
        </div>
        <Button disabled={!loginPassword || loading} type="submit">
          Login
        </Button>
      </div>
      {loginError ? (
        <p className="mt-2 text-sm text-red-600">{loginError}</p>
      ) : null}
      <p className="mt-4 text-sm">
        <button
          className={classNames(
            "text-always-gray-500 focus:outline-none",
            !loadingForgot && `hover:text-always-gray-600 hover:underline`
          )}
          disabled={loadingForgot}
          onClick={onForgot}
        >
          {loadingForgot
            ? `Sending password reset link...`
            : `Forgot password?`}
        </button>
      </p>
    </form>
  ) : state === STATE_PASSWORD_RESET_SENT ? (
    <div>
      <p className="leading-snug text-always-gray-600 text-sm">
        An email with password reset instructions has been sent to{" "}
        <span class="font-medium">{email}</span>.
      </p>
    </div>
  ) : null;
}
